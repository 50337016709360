export const onboardingSteps = [
   {
      target: 'body',
      title: 'Добро пожаловать в Веб-мессенджер',
      content: 'Это небольшая экскурсия по функциональности приложения',
      placement: 'center',
      disableBeacon: true
   },
   {
      target: '#dialogs',
      title: 'Диалоги',
      content: 'Здесь находится список диалогов с вашими контактами. Для каждого диалога вы можете назначать статусы и теги.',
      placement: 'right',
      disableBeacon: true
   },
   {
      target: '#contacts',
      title: 'Контакты',
      content: 'Здесь находится список ваших контактов, сюда же вы можете добавлять новые.',
      placement: 'right',
      disableBeacon: true
   },
   {
      target: '#managers',
      title: 'Менеджеры',
      content: 'Здесь вы можете добавлять менеджеров, которые будут отвечать на сообщения клиентов, а также управлять доступами и правами менеджеров.',
      placement: 'right',
      disableBeacon: true
   },
   {
      target: '#mailing',
      title: 'Рассылки',
      content: 'Здесь вы можете создавать и запускать рассылки по клиентам, настраивать аудитории и просматривать историю рассылок.',
      placement: 'right',
      disableBeacon: true
   },
   {
      target: '#settings',
      title: 'Настройки',
      content: 'Здесь вы можете создавать шаблоны, теги и статусы для ваших диалогов.',
      placement: 'right-end',
      disableBeacon: true
   },
   {
      target: '#logout-btn',
      title: 'Выход из аккаунта',
      content: 'Нажмите, если хотите выйти из аккаунта.',
      placement: 'right-end',
      disableBeacon: true
   }
]