import React from 'react'
import './Tooltip.css'

const Tooltip = ({ text, className = '', position = 'right' }) => {
   const getPosition = (position) => {
      switch (position) {
         case 'top': return 'top'
         case 'bottom': return 'bottom'
         case 'left': return 'left'
         default: return 'right'
      }
   }

   return (
      <div className={`tooltip tooltip_${getPosition(position)} ${className}`}>
         <div className='tooltip__body'>
            {text}
         </div>
      </div>
   )
}

export default Tooltip