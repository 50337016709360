import React, { useEffect, useState } from 'react'
import './Notificator.css'

const Notificator = ({ showActionBtn, onClose, onClick }) => {
   const [show, setShow] = useState(true)

   const handleToggleNotification = () => {
      setShow(show => !show)
   }

   const handleClick = () => {
      onClick()
   }

   useEffect(() => {
      const timeoutId = setTimeout(onClose, 15000)

      return () => clearTimeout(timeoutId)
   }, [])

   return (
      <div className='notificator'>
         <div className='notificator__body'>
            <button 
               className='notificator__btn'
               onClick={handleToggleNotification}
            >
               <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.1781 14.666C24.954 21.8333 28 23.9993 28 23.9993H4C4 23.9993 8 21.1549 8 11.1993C8 8.93616 8.84285 6.76568 10.3431 5.16538C11.8434 3.56506 13.8783 2.66602 16 2.66602C16.4497 2.66602 16.8956 2.70642 17.3333 2.78534" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M25.3335 10.666C27.5427 10.666 29.3335 8.87515 29.3335 6.66602C29.3335 4.45688 27.5427 2.66602 25.3335 2.66602C23.1243 2.66602 21.3335 4.45688 21.3335 6.66602C21.3335 8.87515 23.1243 10.666 25.3335 10.666Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.3067 28C18.0723 28.4041 17.7359 28.7396 17.331 28.9727C16.9262 29.2059 16.4672 29.3287 16 29.3287C15.5328 29.3287 15.0739 29.2059 14.6691 28.9727C14.2643 28.7396 13.9278 28.4041 13.6934 28" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </button>
            {show && (
               <div className='notificator__notification-item notification-item'>
                  <p className='notification-item__text'>
                     За некоторыми каналами, статусами и тегами не закреплен ни один менеджер, доступ к соответствующим диалогам есть только у администратора
                  </p>
                  {showActionBtn && (<button 
                     className='notification-item__btn'
                     onClick={handleClick}
                  >
                     Назначить менеджера
                     <svg width="12" height="9" viewBox="0 0 12 9" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4C0.723858 4 0.5 4.22386 0.5 4.5C0.5 4.77614 0.723858 5 1 5L1 4ZM11.3536 4.85355C11.5488 4.65829 11.5488 4.34171 11.3536 4.14645L8.17157 0.964467C7.97631 0.769205 7.65973 0.769204 7.46447 0.964467C7.2692 1.15973 7.2692 1.47631 7.46447 1.67157L10.2929 4.5L7.46447 7.32843C7.2692 7.52369 7.2692 7.84027 7.46447 8.03553C7.65973 8.2308 7.97631 8.2308 8.17157 8.03553L11.3536 4.85355ZM1 5L11 5L11 4L1 4L1 5Z" fill="#40B7D9"/>
                     </svg>
                  </button>)}
               </div>
            )}
         </div>
      </div>
   )
}

export default Notificator