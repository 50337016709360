import React, { useState } from 'react'
import '../templates.css'
import WarningModal from '../../modals/WarningModal'

const TemplateSubsectionItem = ({ 
   subsection,
   onEdit, 
   onDelete
}) => {
   const { subsection_id, name } = subsection

   const [showDeleteWarning, setShowDeleteWarning] = useState(false)
   const [disableDeleteBtn, setDisableDeleteBtn] = useState(false)

   const toggleDeleteWarning = () => setShowDeleteWarning(show => !show)

   const handleEdit = () => onEdit(subsection)

   const handleDelete = async () => {
      try {
         setDisableDeleteBtn(true)
         await onDelete(subsection_id)
      } catch (err) {
         setDisableDeleteBtn(false)
      }
   }

   return (
      <>
         <WarningModal
            isVisible={showDeleteWarning}
            title={'Вы действительно хотите удалить подраздел?'}
            text={''}
            yes={handleDelete}
            no={toggleDeleteWarning}
            disableYes={disableDeleteBtn}
         />
         <li className="template-item template-subsection-item">
            <div className="template-item_block">
               <div lang="ru" className="template-item_name">
                  <span className="template-item-value">{name}</span>
               </div>
               <div className="template-item_buttons">
                  <div
                     onClick={handleEdit}
                     className="template-item_setting_button"
                  >
                     <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M1.60449 12.75C1.60449 12.5084 1.80037 12.3125 2.04199 12.3125H12.542C12.7836 12.3125 12.9795 12.5084 12.9795 12.75C12.9795 12.9916 12.7836 13.1875 12.542 13.1875H2.04199C1.80037 13.1875 1.60449 12.9916 1.60449 12.75Z"
                           fill="#158CAE"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M9.244 1.8125C9.36009 1.81248 9.47143 1.8586 9.55351 1.94071L11.6841 4.07224C11.8549 4.24308 11.8549 4.51998 11.6841 4.69082L5.65162 10.726C5.56957 10.808 5.45826 10.8542 5.34219 10.8542H3.20801C2.96638 10.8542 2.77051 10.6583 2.77051 10.4167V8.2933C2.77051 8.17736 2.81653 8.06616 2.89846 7.98413L8.93453 1.94083C9.01658 1.85869 9.1279 1.81252 9.244 1.8125ZM9.2442 2.86897L3.64551 8.47437V9.97917H5.16092L10.7561 4.38153L9.2442 2.86897Z"
                           fill="#158CAE"
                        />
                     </svg>
                  </div>
                  <button
                     onClick={toggleDeleteWarning}
                     className="template-item_setting_button"
                  >
                     <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M2.1875 3.41663C2.1875 3.175 2.38338 2.97913 2.625 2.97913H11.375C11.6166 2.97913 11.8125 3.175 11.8125 3.41663V13.3333C11.8125 13.5749 11.6166 13.7708 11.375 13.7708H2.625C2.38338 13.7708 2.1875 13.5749 2.1875 13.3333V3.41663ZM3.0625 3.85413V12.8958H10.9375V3.85413H3.0625Z"
                           fill="#158CAE"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M5.83301 5.89587C6.07463 5.89587 6.27051 6.09175 6.27051 6.33337V10.125C6.27051 10.3667 6.07463 10.5625 5.83301 10.5625C5.59138 10.5625 5.39551 10.3667 5.39551 10.125V6.33337C5.39551 6.09175 5.59138 5.89587 5.83301 5.89587Z"
                           fill="#158CAE"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M8.16699 5.89587C8.40862 5.89587 8.60449 6.09175 8.60449 6.33337V10.125C8.60449 10.3667 8.40862 10.5625 8.16699 10.5625C7.92537 10.5625 7.72949 10.3667 7.72949 10.125V6.33337C7.72949 6.09175 7.92537 5.89587 8.16699 5.89587Z"
                           fill="#158CAE"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M0.729492 3.41663C0.729492 3.175 0.925368 2.97913 1.16699 2.97913H12.8337C13.0753 2.97913 13.2712 3.175 13.2712 3.41663C13.2712 3.65825 13.0753 3.85413 12.8337 3.85413H1.16699C0.925368 3.85413 0.729492 3.65825 0.729492 3.41663Z"
                           fill="#158CAE"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M5.24264 1.45633C5.31944 1.31622 5.46651 1.22913 5.62628 1.22913H8.39365C8.55475 1.22913 8.70283 1.31767 8.77906 1.4596L9.71908 3.2096C9.79191 3.34519 9.78814 3.50907 9.70915 3.64116C9.63016 3.77325 9.48757 3.85413 9.33366 3.85413H4.66699C4.51242 3.85413 4.36932 3.77256 4.29055 3.63956C4.21179 3.50656 4.20905 3.34187 4.28335 3.20633L5.24264 1.45633ZM5.88538 2.10413L5.40574 2.97913H8.60203L8.13203 2.10413H5.88538Z"
                           fill="#158CAE"
                        />
                     </svg>
                  </button>
               </div>
            </div>
         </li>
      </>
   )
}

export default TemplateSubsectionItem