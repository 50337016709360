import uniqid from 'uniqid'

export const allColors = [
   { id: uniqid(), color: '#5399EB', isChecked: false },
   { id: uniqid(), color: '#ED5656', isChecked: false },
   { id: uniqid(), color: '#ED8942', isChecked: false },
   { id: uniqid(), color: '#FFCF40', isChecked: false },
   { id: uniqid(), color: '#43DB4A', isChecked: false },
   { id: uniqid(), color: '#614051', isChecked: false },
   { id: uniqid(), color: '#990066', isChecked: false },
   { id: uniqid(), color: '#0C4974', isChecked: false },
   { id: uniqid(), color: '#2D8567', isChecked: false },
   { id: uniqid(), color: '#C9AE80', isChecked: false },
   { id: uniqid(), color: '#B639E2', isChecked: false },
   { id: uniqid(), color: '#E245A3', isChecked: false },
   { id: uniqid(), color: '#E26CCD', isChecked: false },
   { id: uniqid(), color: '#5C3087', isChecked: false },
   { id: uniqid(), color: '#73CE8F', isChecked: false },
   { id: uniqid(), color: '#2271B3', isChecked: false },
   { id: uniqid(), color: '#93ABCB', isChecked: false },
   { id: uniqid(), color: '#B8311E', isChecked: false },
   { id: uniqid(), color: '#98BA10', isChecked: false },
   { id: uniqid(), color: '#6356F7', isChecked: false },
]
