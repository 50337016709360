import React from 'react'
import './AttachmentsBox.css'

const FilesQty = ({ filesQty = 0 }) => {
   return (
      <div className="file-quantity">
         <span className="file-quantity_number">{filesQty}</span>
      </div>
   )
}

export default FilesQty