import { REVOKE_STATUS } from "../components/utils/helper"
import { WHATSAPP } from "./consts"

const MAX_TIME_REVOKE_MESSAGE = 60 * 60 * 60; //60 hours
const CAN_REVOKE_CHANNELS = [WHATSAPP] 

const checkCanRevokeMessage = (message) => {
   if (message.is_incoming || message.status === REVOKE_STATUS || !CAN_REVOKE_CHANNELS.includes(message.author?.type)) {
      return false
   }
   let canRevokeTimestamp = message.timestamp + MAX_TIME_REVOKE_MESSAGE
   let dateNow = Math.floor(Date.now() / 1000)
   return canRevokeTimestamp >= dateNow;
}

export default checkCanRevokeMessage