import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   contactsLoading: false,
   contacts: [],
   selectedContact: null
}

const contactsSlice = createSlice({
   name: 'contacts',
   initialState,
   reducers: {
      fetchContactsStarted: (state) => {
         state.contactsLoading = true
      },
      fetchContactsSuccess: (state, action) => {
         state.contacts = [...state.contacts, ...action.payload] 
      },
      fetchContactsFinished: (state) => {
         state.contactsLoading = false
      },
      setContacts: (state, action) => {
         state.contacts = action.payload
      },
      setSelectedContact: (state, action) => {
         state.selectedContact = action.payload
      }
   }
})

export const {
   fetchContactsStarted,
   fetchContactsSuccess,
   fetchContactsFinished,
   setContacts,
   setSelectedContact
} = contactsSlice.actions
export default contactsSlice.reducer