const getIsResetPasswordTokenExpired = (token) => {
   if (!token) return true

   const splittedToken = token.split('_')
   const tokenTimestamp = Number(splittedToken[splittedToken.length - 1])
   const maxLifetime = 24 * 60 * 60
   const currentTimestamp = Math.floor(Date.now() / 1000)

   return currentTimestamp - tokenTimestamp > maxLifetime
}

export default getIsResetPasswordTokenExpired