import MessagesService from "../../../services/messagesService"
import { 
   fetchMessagesSuccess,
   setMessages,
   setMessagesLoader,
   editMessageSuccess
} from "."
import { setMessagesFilterCursor } from "../messagesFilter"

export const fetchMessages = (query, fetchNextPortion = false) => async (dispatch, getState) => {
   try {
      dispatch(setMessagesLoader(true))
      if (!fetchNextPortion) dispatch(setMessages([]))

      const cursor = getState().messagesFilter.cursor
      if (fetchNextPortion && !cursor) return dispatch(setMessagesLoader(false))

      const data = await MessagesService.fetchMessages(query)
      if (!data.error) {
         dispatch(fetchMessagesSuccess(data.data.messages))
         dispatch(setMessagesFilterCursor(data.data.next_cursor))
         dispatch(setMessagesLoader(false))
         return data.data
      }
   } catch (err) {
      console.log(err)
      if (window.navigator.onLine) dispatch(setMessagesLoader(false))
   }
}

export const toggleMessageStatus = (message) => async (dispatch) => {
   try {
      const data = await MessagesService.toggleMessageStatus(message)

      if (data.error) {
         alert('Произошла ошибка!')
      }
   } catch (err) {
      console.log(err)
      alert('Произошла ошибка!')
   }
}

export const readMessages = (unreadMessagesIds) => async (dispatch) => {
   try {
      const data = await MessagesService.readMessages(unreadMessagesIds)

      if (data.error) {
         console.log(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const editMessage = (body) => async (dispatch) => {
   try {
      const data = await MessagesService.editMessage(body)

      if (!data.error) {
         dispatch(editMessageSuccess(data.data))
         return
      }
      
      if (data.data.error === "The message can be update only within 15 minutes from the moment of sending." ) {
         return Promise.reject('Изменить текст сообщения можно только в течение 15 минут с момента его отправки.')
      } else {
         return Promise.reject('Не удалось изменить текст сообщения')
      }
   } catch (err) {
      console.log(err)
   }
}

export const forwardMessages = (body) => async (dispatch) => {
   try {
      const data = await MessagesService.forwardMessages(body)

      if (data.error) {
         alert('Не удалось выполнить пересылку сообщений!')
      }
   } catch (err) {
      console.log(err)
   }
}

export const revokeMessage = (body) => async (dispatch) => {
   try {
      const data = await MessagesService.revokeMessage(body)

      if (data.error) {
         alert('Не удалось удалить сообщение!')
      }
   } catch (err) {
      console.log(err)
   }
}