import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   dialogStepsLoading: false,
   dialogSteps: [],
   dialogStepsWithoutRelations: []
}

const dialogStepsSlice = createSlice({
   name: 'dialogStepsSlice',
   initialState,
   reducers: {
      fetchDialogStepsStarted: (state) => {
         state.dialogStepsLoading = true
      },
      fetchDialogStepsSuccess: (state, action) => {
         state.dialogSteps = [ ...state.dialogSteps, ...action.payload ]
         state.dialogStepsLoading = false
      },
      fetchDialogStepsFinished: (state) => {
         state.dialogStepsLoading = false
      },
      createDialogStepSuccess: (state, action) => {
         state.dialogSteps = [ ...state.dialogSteps, action.payload ]
      },
      updateDialogStepSuccess: (state, action) => {
         state.dialogSteps = state.dialogSteps.map(dialogStep => dialogStep.id === action.payload.id
            ? { ...action.payload }
            : dialogStep
         )
      },
      deleteDialogStepSuccess: (state, action) => {
         state.dialogSteps = state.dialogSteps.filter(dialogStep => dialogStep.id !== action.payload)
      },
      fetchDialogStepsWithoutRelationsSuccess: (state, action) => {
         state.dialogStepsWithoutRelations = [ ...action.payload ]
      }
   }
})

export const {
   fetchDialogStepsStarted,
   fetchDialogStepsSuccess,
   fetchDialogStepsFinished,
   createDialogStepSuccess,
   updateDialogStepSuccess,
   deleteDialogStepSuccess,
   fetchDialogStepsWithoutRelationsSuccess
} = dialogStepsSlice.actions
export default dialogStepsSlice.reducer