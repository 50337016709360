import React from 'react'
import './ReturnBtn.css'

const ReturnBtn = ({ isMainbarTablet, onClick }) => {
   const handleClick = () => {
      onClick()
   }

   return (
      <div
         className={`mainbar-sidebar-toggle-left-button ${isMainbarTablet ? 'mainbar-sidebar-toggle-left-button_show' : ''}`}
         onClick={handleClick}
      >
         <img
            src={`/assets/controls/chevron-left.svg`}
            alt=""
            style={{ filter: 'invert(0.7)' }}
         />
      </div>
   )
}

export default ReturnBtn