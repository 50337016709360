import React from 'react'
import './Loader.css'

const Loader = ({ className = '' }) => {
   return (
      <div className={`convo-loader ${className}`}>
         <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   )
}

export default Loader