import React from 'react'
import '../ForgotPasswordPage/ForgotPasswordPage.css'

const ResetPasswordEmailSentPage = () => {
   return (
      <div className="auth">
         <div className="login-card forgot-password-page__card">
            <div className="login-card-wrapper forgot-password-page__wrapper">
               <div className="login-card-header-wrapper forgot-password-page__header-wrapper">
                  <h3
                     className="login-card-header-text forgot-password-page__header"
                  >
                     WEB MESSENGER
                  </h3>
               </div>
               <div className='forgot-password-page__description'>
                  <h4 className='forgot-password-page__description-header'>
                     Восстановление пароля
                  </h4>
                  <p className='forgot-password-page__description-text'>
                     Для дальнейшей смены пароля перейдите по ссылке, отправленной на вашу почту
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ResetPasswordEmailSentPage