import React, {useEffect, useState} from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './EditManagerForm.css'
import { modalsData, managerStatuses } from '../../utils/consts'
import EditManagerFormControl from './EditManagerFormControl'
import EditManagerFormRadioBtn from './EditManagerFormRadioBtn'
import ManagerEditFormModal from '../modals/ManagerEditFormModal'
import { Button, btnThemes } from '../Button'

const modalTypes = {
   status: 'status',
   delete: 'delete'
}

const EditManagerForm = ({ 
   managerData, 
   onStatusChange, 
   onSubmit,
   onDelete
}) => {
   const [controlsInEditMode, setControlsInEditMode] = useState(0)
   const [isEditMode, setIsEditMode] = useState(false)
   const [disableSaveBtn, setDisableSaveBtn] = useState(false)
   const [modalData, setModalData] = useState({
      isVisible: false,
      ...modalsData.managerEditForm.status
   })
   
   const formik = useFormik({
      initialValues: {
         username: managerData.username,
         email: managerData.email,
         password: ''
      },
      validationSchema: yup.object().shape({
         username: yup.string().required('Введите имя пользователя'),
         email: yup.string().required('Введите адрес электронной почты'),
         password: yup.string().min(8, 'Пароль должен содержать не менее 8 символов')
      }),
      onSubmit: async ({ username, email, password }, { setStatus }) => {
         try {
            setDisableSaveBtn(true)
            if (
               username !== managerData.username ||
               email !== managerData.email ||
               password.length
            ) {
               const formData = { username, email }
               password.length && (formData.password = password)
               await onSubmit(managerData.id, formData)
            }
            setControlsInEditMode(0)
            setIsEditMode(false)
            setStatus(null)
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSaveBtn(false)
         }
      }
   })

   const handleToggleFormEditMode = (isEditMode) => {
      setControlsInEditMode(qty => qty + (isEditMode ? 1 : -1))
   }

   const handleManagerStatusChange = async (e) => {
      const value = e.target.value
      await onStatusChange(managerData.id, value)
   }

   const handleCloseModal = () => setModalData(data => ({ ...data, isVisible: false}))

   const handleDeleteManager = () => {
      onDelete(managerData.id)
      handleCloseModal()
   }

   const getModalDataByType = (type) => {
      switch (type) {
         case modalTypes.status:
            return {
               ...modalsData.managerEditForm.status,
               onConfirm: handleCloseModal,
               onClose: handleCloseModal
            }
         case modalTypes.delete:
            return {
               ...modalsData.managerEditForm.delete,
               onConfirm: handleDeleteManager,
               onClose: handleCloseModal
            }
         default: return
      }
   }

   const handleOpenModal = (type) => {
      const modalData = getModalDataByType(type)
      setModalData(data => ({ 
         ...data,
         isVisible: true,
         ...modalData
      }))
   }

   useEffect(() => {
      setIsEditMode(controlsInEditMode > 0)
   }, [controlsInEditMode])

   useEffect(() => {
      formik.setValues({
        username: managerData.username,
        email: managerData.email,
        password: ''
      });
   }, [managerData])

   return (
      <form 
         className='edit-manager-form'
         onSubmit={formik.handleSubmit}
      >
         {formik.status && (<p className='edit-manager-form__status'>{formik.status}</p>)}
         <EditManagerFormControl
            isFormInEditMode={isEditMode}
            initialValue={managerData.username}
            id='username'
            name='username'
            value={formik.values.username}
            label='Имя'
            error={formik.touched.username && formik.errors.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onToggleFieldEditMode={handleToggleFormEditMode}
         />
         <EditManagerFormControl
            isFormInEditMode={isEditMode}
            initialValue={managerData.email}
            id='email'
            name='email'
            value={formik.values.email}
            label='Почта'
            error={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onToggleFieldEditMode={handleToggleFormEditMode}
         />
         <EditManagerFormControl
            isFormInEditMode={isEditMode}
            type='password'
            id='password'
            name='password'
            value={formik.values.password}
            label='Пароль'
            error={formik.touched.password && formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onToggleFieldEditMode={handleToggleFormEditMode}
            placeholder='*********'
         />
         <div className='edit-manager-form-control edit-manager-form-radio-control'>
            <p className='edit-manager-form-radio-control__label'>
               Статус менеджера
               <button 
                  className='edit-manager-form-radio-control__warning-btn'
                  type='button'
                  onClick={() => handleOpenModal(modalTypes.status)}
               >
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M6 7.375C5.795 7.375 5.625 7.205 5.625 7V4.375C5.625 4.17 5.795 4 6 4C6.205 4 6.375 4.17 6.375 4.375V7C6.375 7.205 6.205 7.375 6 7.375Z" fill="#39B0D2"/>
                     <path d="M6 9.12462C5.865 9.12462 5.73999 9.07463 5.64499 8.97963C5.59999 8.92963 5.565 8.87462 5.535 8.81462C5.51 8.75462 5.5 8.68962 5.5 8.62462C5.5 8.49462 5.55499 8.36461 5.64499 8.26961C5.82999 8.08461 6.17001 8.08461 6.35501 8.26961C6.44501 8.36461 6.5 8.49462 6.5 8.62462C6.5 8.68962 6.48499 8.75462 6.45999 8.81462C6.43499 8.87462 6.40001 8.92963 6.35501 8.97963C6.26001 9.07463 6.135 9.12462 6 9.12462Z" fill="#39B0D2"/>
                     <circle cx="5.9998" cy="6.50078" r="4.8" stroke="#39B0D2" stroke-width="0.8"/>
                  </svg>
               </button>
            </p>
            <div className='edit-manager-form-radio-control__body'>
               <EditManagerFormRadioBtn
                  name='status'
                  value={managerStatuses.active}
                  currentValue={managerData.status}
                  text='Активный'
                  onChange={handleManagerStatusChange}
               />
               <EditManagerFormRadioBtn
                  name='status'
                  value={managerStatuses.inactive}
                  currentValue={managerData.status}
                  text='Неактивный'
                  onChange={handleManagerStatusChange}
               />
            </div>
         </div>
         <div className='edit-manager-form__footer'>
            {isEditMode && (
               <Button 
                  type='submit'
                  text='Сохранить изменения'
                  disabled={disableSaveBtn}
               />
            )}
            <Button
               theme={btnThemes.DANGER}
               customStyle='edit-manager-form__btn_delete'
               text='Удалить менеджера'
               onClick={() => handleOpenModal(modalTypes.delete)}
            >
               <svg
                  fill="#FFFFFF"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M2.1875 3.41663C2.1875 3.175 2.38338 2.97913 2.625 2.97913H11.375C11.6166 2.97913 11.8125 3.175 11.8125 3.41663V13.3333C11.8125 13.5749 11.6166 13.7708 11.375 13.7708H2.625C2.38338 13.7708 2.1875 13.5749 2.1875 13.3333V3.41663ZM3.0625 3.85413V12.8958H10.9375V3.85413H3.0625Z"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M5.83301 5.89587C6.07463 5.89587 6.27051 6.09175 6.27051 6.33337V10.125C6.27051 10.3667 6.07463 10.5625 5.83301 10.5625C5.59138 10.5625 5.39551 10.3667 5.39551 10.125V6.33337C5.39551 6.09175 5.59138 5.89587 5.83301 5.89587Z"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M8.16699 5.89587C8.40862 5.89587 8.60449 6.09175 8.60449 6.33337V10.125C8.60449 10.3667 8.40862 10.5625 8.16699 10.5625C7.92537 10.5625 7.72949 10.3667 7.72949 10.125V6.33337C7.72949 6.09175 7.92537 5.89587 8.16699 5.89587Z"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M0.729492 3.41663C0.729492 3.175 0.925368 2.97913 1.16699 2.97913H12.8337C13.0753 2.97913 13.2712 3.175 13.2712 3.41663C13.2712 3.65825 13.0753 3.85413 12.8337 3.85413H1.16699C0.925368 3.85413 0.729492 3.65825 0.729492 3.41663Z"
                  />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M5.24264 1.45633C5.31944 1.31622 5.46651 1.22913 5.62628 1.22913H8.39365C8.55475 1.22913 8.70283 1.31767 8.77906 1.4596L9.71908 3.2096C9.79191 3.34519 9.78814 3.50907 9.70915 3.64116C9.63016 3.77325 9.48757 3.85413 9.33366 3.85413H4.66699C4.51242 3.85413 4.36932 3.77256 4.29055 3.63956C4.21179 3.50656 4.20905 3.34187 4.28335 3.20633L5.24264 1.45633ZM5.88538 2.10413L5.40574 2.97913H8.60203L8.13203 2.10413H5.88538Z"
                  />
               </svg>
            </Button>
         </div>
         <ManagerEditFormModal {...modalData} />
      </form>
   )
}

export default EditManagerForm