import qs from "qs"
import { dateStatusNamesMap, DEFAULT_LIMIT } from "./consts/defaultDialogsParams"

const getDialogsDefaultParams = () => ({
   limit: DEFAULT_LIMIT,
   new_messages: false
})

export const mapDialogsSearchToQuery = (search) => {
   const defaultParams = getDialogsDefaultParams()
   const params = qs.parse(search.slice(1))
   return qs.stringify({ ...defaultParams, ...params }, { addQueryPrefix: true })
}

export const mapDialogsSearchToFilter = (search) => {
   const params = qs.parse(search.slice(1))
   const filter = {}
   filter.limit = params.limit || DEFAULT_LIMIT
   filter.searchValue = params.client_query || ''
   filter.cursor = params.cursor || null
   filter.unreadOnly = params.new_messages === 'true' ? true : false
   filter.dateStatus = {
      name: dateStatusNamesMap[params.date_tag] || 'Все',
      value: params.date_tag || null
   }
   return filter
}

export const mapDialogsFilterToSearch = (filter) => {
   const params = {}
   params.limit = filter.limit
   filter.searchValue !== '' && (params.client_query = filter.searchValue)
   params.new_messages = filter.unreadOnly
   filter.dateStatus.value !== null && (params.date_tag = filter.dateStatus.value)
   return qs.stringify(params, { addQueryPrefix: true })
}

export const mapDialogsFilterToQuery = (filter) => {
   const params = qs.parse(mapDialogsFilterToSearch(filter).slice(1))
   filter.cursor && (params.cursor = filter.cursor)
   filter.dateStatus.value !== null && (params.date_tag = filter.dateStatus.value)
   return qs.stringify(params, { addQueryPrefix: true })
}