import './OgvPlayer.css';
import {useEffect, useMemo, useRef} from "react";
import {useState} from "react";
import uniqid from "uniqid";

function simtrunc(val) {
    if (val >= 0) {
        return Math.floor(val);
    } else {
        return Math.ceil(val);
    }
}

const trunc = Math.trunc || simtrunc;

function formatTime(time) {
    const rTime = Math.round(time),
        minutes = trunc(rTime / 60),
        seconds = Math.abs(rTime % 60),
        padding = (seconds < 10) ? '0' : '';
    return minutes + ':' + padding + seconds;
}

function percent(val, total) {
    const ratio = val / total,
        percentage = ratio * 100.0;
    return percentage + '%';
}

let init =  (src , containerId) => {
    let player;
    let ogv = require('ogv');

    ogv.OGVLoader.base = '/assets/ogv_player/lib';

    player =  new ogv.OGVPlayer();
    player.src = src;

    document.getElementById(containerId).appendChild(player);
    return player;
}

function OgvPlayer({src}) {
    const [isPlay, setIsPlay] = useState(false);
    const [totalTime, setTotalTime] = useState('-:--');
    const [elapsedTime, setElapsedTime] = useState('-:--');
    const [isMute, setIsMute] = useState(false); // Используется useEffect для отслеживания изменений

    let containerId = useMemo(() => {
        return uniqid('ogv_container_')
    }, []);

    let playerRef = useRef({});

    useEffect(() => {
        (async () => {
            const player = await init(src, containerId);
            playerRef.current = player
            let updateTime;

            playerRef.current.addEventListener('loadedmetadata', function() {
                playerRef.current.duration ? setTotalTime(formatTime(playerRef.current.duration)) : setTotalTime('0:00');
                setElapsedTime('0:00')
            });

            playerRef.current.addEventListener('play', function() {
                updateTime = setInterval(() => {
                    setElapsedTime(formatTime(player.currentTime));
                }, 1000)
            });

            playerRef.current.addEventListener('ended', function() {
                if (updateTime) {
                    clearInterval(updateTime);
                }
                setElapsedTime('0:00');
                setIsPlay(false)
            });
        })()
    }, []);

    useEffect(() => {
        playerRef.current.muted = isMute === true;
    }, [isMute]);

    const start = () => {
        playerRef.current.play();
        setIsPlay(true);
    }

    const pause = () => {
        playerRef.current.pause();
        setIsPlay(false);
    }

    return (
        <div className="custom-player">
            <div id={containerId} className="player-container"/>
            <div className="custom-player-wrap">
                <div className="custom-player-control">
                    {
                        isPlay ?
                            <button className="custom-player-button custom-player-stop-button" onClick={pause} title="Stop">
                                <div className="custom-player-stop-button-img-wrap">
                                    <img className="custom-player-stop-button-img" src="/assets/ogv_player/icons/pause.svg" alt=""/>
                                </div>
                            </button>
                            :
                            <button disabled={!playerRef.current?.duration} className="custom-player-button custom-player-start-button" onClick={start} title="Play"/>
                    }
                </div>
                <div className="custom-player-nav">
                    <div className="custom-player-nav-time">
                        <div className="custom-player-nav-current">{elapsedTime}</div>
                        <div className="custom-player-nav-separate">/</div>
                        <div className="custom-player-nav-total">{totalTime}</div>
                    </div>
                    <div className="custom-player-nav-progress-wrap">
                        <div className="custom-player-nav-progress">
                            <div className="custom-player-nav-progress-val" style={{width: percent(playerRef.current?.currentTime, playerRef.current?.duration)}}/>
                        </div>
                    </div>
                </div>
                <div className="custom-player-download-mute">
                    <button className="custom-player-button custom-player-download-mute-button" onClick={() => setIsMute(!isMute)} title="Mute">
                        <div className="custom-player-download-mute-img-wrap">
                            {
                                isMute ?
                                    <img className="custom-player-download-mute-img" src="/assets/ogv_player/icons/mute.svg" alt=""/>
                                    :
                                    <img className="custom-player-download-mute-img" src="/assets/ogv_player/icons/speaker.svg" alt=""/>
                            }
                        </div>
                    </button>
                </div>
                <div className="custom-player-download-file">
                    <a href={src} target="_blank" download title="Скачать файл.">
                        <div className="custom-player-download-file-img-wrap">
                            <img className="custom-player-download-file-img" src="/assets/ogv_player/icons/download.svg" alt=""/>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default OgvPlayer;