import { 
   deleteDialogTagSuccess,
   fetchDialogsSuccess, 
   hoistDialogOnNewMessageSuccess, 
   setDialogStatusSuccess, 
   setDialogStatusSuccessCases,
   setDialogTagSuccess, 
   setDialogs,
   updateDialogNameSuccess,
   setDialogContactDateSuccess,
   setSelectedDialog
} from "."
import { setDialogsFilterCursor } from "../dialogsFilter"
import DialogsService from "../../../services/dialogsService"
import { getNormalizedDialog } from "../../../utils"
import MessagesService from "../../../services/messagesService"
import { auth_selectIsManager, auth_selectUser } from "../auth/selectors"
import { dialogs_selectDialogs, dialogs_selectSelectedDialog } from "./selectors"

export const fetchDialogs = (query, fetchNextPortion = false) => async (dispatch, getState) => {
   try {
      if (!fetchNextPortion) dispatch(setDialogs([]))

      const cursor = getState().dialogsFilter.cursor
      if (fetchNextPortion && !cursor) return

      const data = await DialogsService.fetchDialogs(query)

      if (!data.error) {
         const normalizedDialogs = data.data.dialogs.map((dialog) => getNormalizedDialog(dialog))
         dispatch(fetchDialogsSuccess(normalizedDialogs))
         dispatch(setDialogsFilterCursor(data.data.next_cursor))
      }
   } catch (err) {
      console.log(err)
   }
}

export const updateSelectedDialogOnManagerAccessChange = (id) => async (dispatch, getState) => {
   try {
      const selectedDialog = getState().dialogs.selectedDialog
      const data = await DialogsService.fetchDialog(id)
      
      if (!data.error) {
         if (data.data.dialogs[0]) {
            const normalizedDialog = getNormalizedDialog(data.data.dialogs[0])
            dispatch(setSelectedDialog(normalizedDialog))  
         } else {            
            dispatch(setSelectedDialog({ ...selectedDialog, read_only: true }))
         }
      }
   } catch (err) {
      console.log(err)
   }
}

export const readDialog = (id) => async (dispatch) => {
   try {
      const data = await MessagesService.readDialog(id)

      if (data.error) {
         alert(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const setDialogStatus = (dialogId, status) => async (dispatch, getState) => {
   try {
      const state = getState()
      const isManager = auth_selectIsManager(state)
      const user = auth_selectUser(state)
      const allowedStatuses = user?.allowed_status

      const data = await DialogsService.setDialogStatus(dialogId, status.value)

      if (!data.error) {
         if (isManager && !user.read_only_available && !allowedStatuses.some(statusId => statusId === status.value)) {
            return dispatch(setDialogStatusSuccess({ case: setDialogStatusSuccessCases.statusNotAllowed }))
         }
         dispatch(setDialogStatusSuccess({
            case: setDialogStatusSuccessCases.default,
            status: {
               id: status.value,
               name: status.label,
               color: status.bg
            }
         }))
      } else {
         window.alert(data.data.error)
      }
   } catch (err) {
      window.alert(err)
      window.location.reload(false)
   }
}

export const attachTagToDialog = (tag) => async (dispatch, getState) => {
   try {
      const state = getState()
      const dialogId = dialogs_selectSelectedDialog(state).dialog_id
      const data = await DialogsService.attachTagToDialog(dialogId, tag.value)

      if (!data.error) {
         dispatch(setDialogTagSuccess({ id: tag.value, name: tag.label }))
      } else {
         alert(data.data.error)
      }
   } catch (err) {
      window.alert(err)
      window.location.reload(false)
   }
}

export const deleteAttachedTagFromDialog = (tagId, dialog) => async (dispatch) => {
   try {
      const data = await DialogsService.deleteAttachedTagFromDialog(dialog.dialog_id, tagId)

      if (!data.error) {
         dispatch(deleteDialogTagSuccess(tagId))
      } else {
         window.alert(data.data.error)
      }
   } catch (err) {
      window.alert(err)
      window.location.reload(false)
      return Promise.reject()
   }
}

export const hoistDialogOnNewMessage = (id) => async (dispatch) => {
   try {
      const data = await DialogsService.fetchDialog(id)

      if (!data.error) {
         const normalizedDialog = getNormalizedDialog(data.data.dialogs[0])
         dispatch(hoistDialogOnNewMessageSuccess(normalizedDialog))
      }
   } catch (err) {
      console.log(err)
   }
}

export const updateDialogsOnNewMessage = (newMessage) => async (dispatch, getState) => {
   try {
      const state = getState()
      const dialogs = dialogs_selectDialogs(state)
      const newMessageDialog = dialogs.find(dialog => dialog.dialog_id === newMessage.dialog_id)

      if (newMessageDialog) {
         const updatedNewMessageDialog = {
            ...newMessageDialog,
            status: newMessage.is_incoming ? 1 : newMessageDialog.status,
            is_incoming: newMessage.is_incoming,
            last_message_id: newMessage.id,
            last_message: newMessage.text,
            is_document: newMessage.type, 
            new_message: newMessage.is_incoming ? newMessageDialog.new_message + 1 : newMessageDialog.new_message 
         }
         const filteredDialogs = dialogs.filter(dialog => dialog.dialog_id !== newMessageDialog.dialog_id)
         dispatch(setDialogs([updatedNewMessageDialog, ...filteredDialogs]))
      } else {
         dispatch(hoistDialogOnNewMessage(newMessage.dialog_id))
      }
   } catch (err) {
      console.log(err)
   }
}

export const updateDialogName = (body) => async (dispatch) => {
   try {
      const data = await DialogsService.updateDialogName(body)

      if (!data.error) {
         const normilizedDialog = getNormalizedDialog(data.data)
         
         dispatch(updateDialogNameSuccess(normilizedDialog))
      } else {
         if (data.data.error === 'Manager does not have permission to edit dialogs.') {            
            return Promise.reject('У вас нет прав на изменение имени диалога!')
         }
      }
   } catch (err) {
      return Promise.reject('Не удалось обновить имя диалога.')
   }
}

export const setDialogContactDate = (body) => async (dispatch) => {
   try {
      const data = await DialogsService.setDialogContactDate(body)

      if (!data.error) {
         dispatch(setDialogContactDateSuccess(body))
      } else {
         alert('Не удалось установить дату контакта!')
      }
   } catch (err) {
      alert('Не удалось установить дату контакта!')     
   }
}