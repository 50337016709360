import React, { useState } from 'react'
import './FilterDateSelect.css'

import dateStatuses from './dateStatuses'

const FilterDateSelect = ({ selectedDateStatus, onSelect }) => {
   const [isActive, setIsActive] = useState(false)

   const handleToggleIsActive = () => setIsActive(active => !active)

   const handleSelect = (status) => {
      onSelect(status)
      setIsActive(false)
   }

   return (
      <div className={`filter-date-select ${isActive ? 'active' : ''}`}>
         <p className='filter-date-select__label'>Дата контакта:</p>
         <div className='filter-date-select__body'>
            <button 
               className='filter-date-select__value'
               onClick={handleToggleIsActive}
            >
               {selectedDateStatus.value ? selectedDateStatus.name : 'Не задано'}
               <img 
                  className='filter-date-select__dropdown-icon'
                  src='/assets/sidebar/select-dropdown-icon.svg' alt='' 
               />
            </button>
            {isActive && (
               <div className='filter-date-select__list-container'>
                  <ul className='filter-date-select__list'>
                     {dateStatuses.map(status => {
                        const isSelected = selectedDateStatus.value === status.value
                        return (
                           <li
                              key={status.name}
                              className='filter-date-select__list-item'
                              onClick={() => handleSelect(status)}
                           >
                              <div className={
                                 `filter-date-select-status ${
                                 isSelected
                                    ? 'filter-date-select-status_selected' 
                                    : ''
                                 } filter-date-select-status_${status.value}`
                              }>
                                 <div className='filter-date-select-status__checkbox'>
                                    {isSelected && <img src='/assets/sidebar/select-checkbox-icon.svg' alt='' />}
                                 </div>
                                 <div className='filter-date-select-status__status'>
                                    {status.name}
                                 </div>
                              </div>
                           </li>
                        )
                     })}
                  </ul>
               </div>
            )}
         </div>
      </div>
   )
}

export default FilterDateSelect