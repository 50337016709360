import { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import Joyride, { STATUS } from 'react-joyride'
import { onboardingSteps } from '../../utils/consts/onboarding'
import CustomTooltip from './CustomTooltip'
import { userEvents } from '../../utils/consts'

const Onboarding = ({ isCompleted, onComplete, setSidebarToggle, handleMessageTextChange, setToggleSidebarView }) => { 
   const [onboardingCompleted, setOnboardingCompleted] = useState(isCompleted)
   const [steps, setSteps] = useState(onboardingSteps)
   const completingInProgress = useRef(false)

   const navigate = useNavigate()

   const handleComplete = async (status) => {
      completingInProgress.current = true
      const eventData = {
         code: userEvents.onBoarding.code
      }
      switch (status) {
         case STATUS.SKIPPED:
            eventData.value = userEvents.onBoarding.values.skipped
            break
         default:
            eventData.value = userEvents.onBoarding.values.finished
      }
      await onComplete(eventData)
      setOnboardingCompleted(true)
      completingInProgress.current = false
   }

   const handleJoyrideCallback = (data) => {
      const { step: { target }, status } = data

      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && !completingInProgress.current) {
         return handleComplete(status)
      }

      switch (target) {
         case '#dialogs':
            navigate('/dialogs')
            setSidebarToggle(1)
            break;
         case '#contacts':
            navigate('/contacts')
            setSidebarToggle(2)
            handleMessageTextChange('')
            break;
         case '#managers':
            navigate('/managers')
            setSidebarToggle(3)
            break;
         case '#mailing':
            navigate('/mailing')
            setToggleSidebarView(0)
            setSidebarToggle(5)
            break;
         case '#settings':
            navigate('/settings')
            setToggleSidebarView(1)
            setSidebarToggle(4)
            break;
         default:
            navigate('/dialogs')
            setSidebarToggle(1)
      }
   }

   return (
      <Joyride
         run={!onboardingCompleted} 
         tooltipComponent={CustomTooltip}
         continuous
         callback={handleJoyrideCallback}
         steps={steps}
         scrollToFirstStep
         showSkipButton
         disableOverlayClose
      />
   )
}

export default Onboarding