import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_LIMIT } from "../../../utils/consts/defaultDialogsParams";

const initialState = {
   limit: DEFAULT_LIMIT,
   cursor: '',
   dialogId: '',
   searchValue: '',
   unreadOnly: false,
   channelId: '',
   dateStatus: {
      name: 'Все',
      value: null
   }
}

const dialogsFilterSlice = createSlice({
   name: 'dialogsFilter',
   initialState,
   reducers: {
      setDialogsFilterCursor: (state, action) => {
         state.cursor = action.payload
      },
      setDialogsFilterDialogId: (state, action) => {
         state.dialogId = action.payload
      },
      setDialogsFilterSearchValue: (state, action) => {
         state.searchValue = action.payload
         state.cursor = ''
      },
      setDialogsFilterUnreadOnly: (state, action) => {
         state.unreadOnly = action.payload
         state.cursor = ''
      },
      setDialogsFilterChannelId: (state, action) => {
         state.channelId = action.payload
      },
      setDialogsFilterDateStatus: (state, action) => {
         state.dateStatus = action.payload
         state.cursor = ''
      },
      setDialogsFilter: (state, action) => {
         return { 
            ...state,
            ...action.payload 
         }
      }
   }
})

export const {
   setDialogsFilterCursor,
   setDialogsFilterDialogId,
   setDialogsFilterSearchValue,
   setDialogsFilterUnreadOnly,
   setDialogsFilterChannelId,
   setDialogsFilterDateStatus,
   setDialogsFilter
} = dialogsFilterSlice.actions
export default dialogsFilterSlice.reducer