import '../../app.css'

const AuthLoader = () => {
   return (
      <div className="auth-loader">
         <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   )
}

export default AuthLoader