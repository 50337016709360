import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker"
import ru from 'date-fns/locale/ru'

import './ContactDatePicker.css'

import { useClickOutside } from '../../hooks'
import { determineDateStatus, getFormattedDate } from '../../utils'
import { Button, btnSizes } from '../Button'
registerLocale('ru', ru)

const ContactDatePicker = ({ selectedDialog, onContactDateSelect, onContactDateDelete }) => {
   const selectedDate = selectedDialog.follow_up_date ? new Date(selectedDialog.follow_up_date) : new Date(Date.now())
   const dialogContactDateStatus = determineDateStatus(selectedDialog.follow_up_date)

   const [showDatePicker, setShowDatePicker] = useState(false)
   const [disableDeleteBtn, setDisableDeleteBtn] = useState(false)

   const handleToggleDatePicker = () => setShowDatePicker(show => !show)

   const handleCloseDatePicker = () => setShowDatePicker(false)

   const handleDeleteDate = async () => {
      setDisableDeleteBtn(true)
      await onContactDateDelete(selectedDialog.dialog_id)
      setDisableDeleteBtn(false)
   }
   
   const handleDateChange = (date) => {
      handleCloseDatePicker()      
      onContactDateSelect({
         dialog_id: selectedDialog.dialog_id,
         date
      })    
   }

   const datePickerRef = useClickOutside(handleCloseDatePicker)

   return (
      <div ref={datePickerRef} className={`contact-date-picker ${showDatePicker ? 'active' : ''}`}>
         <div className='contact-date-picker__button contact-date-picker-button'>
            <p className='contact-date-picker-button__label'>
               Дата контакта
            </p>
            <button 
               className='contact-date-picker-button__btn'
               onClick={handleToggleDatePicker}
            >
               <span className='contact-date-picker-button__value'>
                  {selectedDialog.follow_up_date ? getFormattedDate(selectedDialog.follow_up_date) : 'Выбор даты контакта'}
                  <img className='contact-date-picker-button__btn-icon' src='/assets/controls/datepicker-down-arrow.svg' alt='' />
               </span>
               {selectedDialog.follow_up_date && (
                  <span className={`contact-date-picker-button__date-status contact-date-picker-button__date-status_${dialogContactDateStatus.style}`}>
                     {dialogContactDateStatus.name}
                  </span>
               )}
            </button>
         </div>
         <div className='contact-date-picker__popup'>
            <DatePicker
               locale='ru'
               wrapperClassName='contact-date-picker__picker-wrapper'
               popperClassName='contact-date-picker__picker'
               className='contact-date-picker__input'
               calendarClassName='contact-date-picker__calendar'
               selected={selectedDate}
               dateFormat={"yyyy-MM-dd"}
               excludeDates={[new Date()]}
               open
               minDate={new Date()}
               onChange={handleDateChange} 
            />
            <Button
               size={btnSizes.SMALL}
               text='Удалить дату контакта'
               onClick={handleDeleteDate}
               disabled={disableDeleteBtn || !selectedDialog.follow_up_date}
            />
         </div>
      </div>
   )
}

export default ContactDatePicker