const getNotificationDataByType = (data, type = 'message') => {
   let title
   let options

   switch (type) {
      default:
         title = data.author.name
         options = {
            body: data.text,
            dir: 'ltr',
         }
   }

   return {
      title,
      options
   }
}

export default getNotificationDataByType