import React, { useState } from 'react'

import './mailing.css'

import useClickOutside from '../../hooks/useClickOutside'
import { mailingTypesMap } from '../../utils/consts'
import { btnIcon, Button } from '../Button'

const MailingTypeSelect = ({ onSelect }) => {
   const [isActive, setIsActive] = useState(false)

   const mailingTypes = Object.values(mailingTypesMap)

   const handleMailingTypeSelectToggle = () => setIsActive(active => !active)

   const handleMailingTypeSelectClose = () => setIsActive(false)

   const handleSelect = (type) => {
      onSelect(type)
      handleMailingTypeSelectClose()
   }

   const mailingTypeSelectRef = useClickOutside(handleMailingTypeSelectClose)

   return (
      <div ref={mailingTypeSelectRef} className={`mailing-type-select ${isActive ? 'active' : ''}`}>
         <Button 
            icon={btnIcon.RIGHT}
            text='Создать рассылку'
            onClick={handleMailingTypeSelectToggle}
         />
         <ul className='mailing-type-select__list'>
            {mailingTypes.map(type => (
               <li 
                  key={type}
                  className='mailing-type-select__list-item'
                  onClick={() => handleSelect(type)}
               >
                  {type}
               </li>
            ))}
         </ul>
      </div>
   )
}

export default MailingTypeSelect