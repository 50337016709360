import React from 'react'
import './DialogsListFallback.css'

const DialogsListFallback = () => {
   return (
      <div className='dialogs-list-fallback'>
         Список диалогов пуст
      </div>
   )
}

export default DialogsListFallback