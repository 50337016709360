import qs from "qs"
import { DEFAULT_LIMIT } from "./consts/defaultContactsParams"

const getContactsDefaultParams = () => ({
   limit: DEFAULT_LIMIT
})

export const mapContactsSearchToQuery = (search) => {
   const defaultParams = getContactsDefaultParams()
   const params = qs.parse(search.slice(1))
   return qs.stringify({ ...defaultParams, ...params }, { addQueryPrefix: true })
}

export const mapContactsSearchToFilter = (search) => {
   const params = qs.parse(search.slice(1))
   const filter = {}
   filter.limit = params.limit || DEFAULT_LIMIT
   filter.searchValue = params.search || ''
   filter.cursor = params.cursor || null
   filter.channel = params.channelType || null
   return filter
}

export const mapContactsFilterToSearch = (filter) => {
   const params = {}
   params.limit = filter.limit
   filter.searchValue !== '' && (params.search = filter.searchValue)
   filter.channel && (params.channelType = filter.channel)
   return qs.stringify(params, { addQueryPrefix: true })
}

export const mapContactsFilterToQuery = (filter) => {
   const params = {}
   params.limit = filter.limit
   filter.searchValue !== '' && (params.search = filter.searchValue)
   filter.channel && (params.channelType = filter.channel)
   filter.cursor && (params.cursor = filter.cursor)
   return qs.stringify(params, { addQueryPrefix: true })
}