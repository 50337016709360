import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   tagsWithoutRelations: []
}

const tagsSlice = createSlice({
   name: 'tagsSlice',
   initialState,
   reducers: {
      fetchTagsWithoutRelationsSuccess: (state, action) => {
         state.tagsWithoutRelations = [ ...action.payload ]
      }
   }
})

export const {
   fetchTagsWithoutRelationsSuccess
} = tagsSlice.actions
export default tagsSlice.reducer