import $axios from '../http'

class AuthService {
   static fetchUser = async () => {
      const { data } = await $axios.get('user/get?')
      return data
   }

   static login = async (body) => {
      const { data } = await $axios.post('manager/login', body, {
         maxRedirects: 1,
         validateStatus: function (status) {
            return status >= 200 && status <= 302
         }
      })
      return data
   }

   static logout = async () => {
      const { data } = await $axios.post('user/logout?')
      return data
   }

   static sendUserEvent = async (body) => {
      const { data } = await $axios.post('user/event', body)
      return data
   }

   static requestResetPassword = async ({ email }) => {
      const { data } = await $axios.post(`manager/request-password?email=${email}`)
      return data
   }

   static resetPassword = async (body) => {
      const { data } = await $axios.post('manager/reset-password', body)
      return data
   }

   static setFollowUpDateEnabled = async (body) => {
      const { data } = await $axios.post('user/set-follow-up-date-enabled', body)
      return data
   }
}

export default AuthService