export const managerAccessNotificationTypes = {
   WARNING: 'warning'
}

export const managerAccessNotificationsData = {
   dialogAccessChanged: {
      header: 'Изменение прав доступа',
      text: 'После выхода из данного диалога, он больше не будет доступен для вас. Для уточнения информации обратитесь к администратору.'
   },
   editDialogAccessChanged: {
      header: 'Изменение прав доступа',
      text: 'После обновления страницы вы не сможете редактировать информацию в диалогах. Для уточнения информации обратитесь к администратору.'
   }
}