import { 
   createTemplateSubsectionSuccess, 
   createTemplateSuccess, 
   deleteTemplateSubsectionSuccess, 
   deleteTemplateSuccess, 
   fetchTemplateSubsectionsFinished, 
   fetchTemplateSubsectionsStarted, 
   fetchTemplateSubsectionsSuccess, 
   fetchTemplatesFinished, 
   fetchTemplatesStarted, 
   fetchTemplatesSuccess, 
   updateTemplateSubsectionSuccess, 
   updateTemplateSuccess 
} from "."
import TemplatesService from "../../../services/templatesService"

export const fetchTemplates = (subsectionId) => async (dispatch) => {
   try {
      dispatch(fetchTemplatesStarted())
      const data = await TemplatesService.fetchTemplates(subsectionId)

      if (!data.error) {
         dispatch(fetchTemplatesSuccess(data.data))
      }
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchTemplatesFinished())
   }
}

export const createTemplate = (template) => async (dispatch) => {
   try {
      const data = await TemplatesService.createTemplate(template)

      if (!data.error) {
         dispatch(createTemplateSuccess(data.data))
      }  else {
         return Promise.reject(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const updateTemplate = (template) => async (dispatch) => {
   try {
      const data = await TemplatesService.updateTemplate(template)

      if (!data.error) {
         dispatch(updateTemplateSuccess(data.data))
      } else {
         return Promise.reject(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const deleteTemplate = (id) => async (dispatch) => {
   try {
      const data = await TemplatesService.deleteTemplate(id)

      if (!data.error) {
         dispatch(deleteTemplateSuccess(id))
      } else {
         return Promise.reject()
      }
   } catch (err) {
      console.log(err)
   }
}

export const fetchTemplateSubsections = () => async (dispatch) => {
   try {
      dispatch(fetchTemplateSubsectionsStarted())
      const data = await TemplatesService.fetchTemplateSubsections()

      if (!data.error) {
         dispatch(fetchTemplateSubsectionsSuccess(data.data))
      }
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchTemplateSubsectionsFinished())
   }
}

export const createTemplateSubsection = (template) => async (dispatch) => {
   try {
      const data = await TemplatesService.createTemplateSubsection(template)

      if (!data.error) {
         dispatch(createTemplateSubsectionSuccess(data.data))
      } else {
         return Promise.reject(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const updateTemplateSubsection = (template) => async (dispatch) => {
   try {
      const data = await TemplatesService.updateTemplateSubsection(template)

      if (!data.error) {
         dispatch(updateTemplateSubsectionSuccess(data.data))
      } else {
         return Promise.reject(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}

export const deleteTemplateSubsection = (id) => async (dispatch) => {
   try {
      const data = await TemplatesService.deleteTemplateSubsection(id)

      if (!data.error) {
         dispatch(deleteTemplateSubsectionSuccess(id))
      } else {
         alert('Данный подраздел не может быть удален, так как он прикреплен к одному из шаблонов!')
         return Promise.reject()
      }
   } catch (err) {
      console.log(err)
   }
}