import React from 'react'
import { CSSTransition } from 'react-transition-group'

import './mailingFileNotification.css'

import { Button } from '../Button'

const MailingFileNotification = ({
   isVisible,
   title,
   file,
   text,
   yes,
   button,
}) => {
   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="warning_wrapper">
               <span className="warning_title">{title}</span>
               <p className="mailing-notification-file">{file}</p>
               <p className="warning_text">{text}</p>
               <div className="warning_buttons">
                  <Button
                     text={button}
                     onClick={yes}
                  />
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default MailingFileNotification
