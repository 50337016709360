import { fetchContactsFinished, fetchContactsStarted, fetchContactsSuccess, setContacts } from "."
import ContactsService from "../../../services/contactsService"
import { setContactsFilterCursor } from "../contactsFilter"

export const fetchContacts = (query, fetchNextPortion = false) => async (dispatch, getState) => {
   try {
      dispatch(fetchContactsStarted())
      if (!fetchNextPortion) dispatch(setContacts([]))

      const cursor = getState().contactsFilter.cursor
      if (fetchNextPortion && !cursor) return

      const data = await ContactsService.fetchContacts(query)

      if (!data.error) {
         dispatch(fetchContactsSuccess(data.data.clients))
         dispatch(setContactsFilterCursor(data.data.next_cursor))
      }
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchContactsFinished())
   }
}