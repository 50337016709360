import { useState } from "react"
import { notificationsData } from "../utils/consts"

const useAppNotification = () => {
   const [notification, setNotification] = useState({
      isVisible: false,
      ...notificationsData.default
   })

   const showNotification = (notificationData) => setNotification({
      isVisible: true,
      ...notificationData
   })

   const closeNotification = () => setNotification(notification => ({
      ...notification,
      isVisible: false
   }))

   return [notification, showNotification, closeNotification]
}

export default useAppNotification