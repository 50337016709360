import TagsService from "../../../services/tagsService"
import { fetchTagsWithoutRelationsSuccess } from "."

export const fetchTagsWithoutRelations = () => async (dispatch) => {
   try {
      const data = await TagsService.checkTagRelations()

      if (!data.error) {
         dispatch(fetchTagsWithoutRelationsSuccess(data.data ? data.data : []))
      }
      
      return data.data
   } catch (err) {
      console.log(err)
   }
}