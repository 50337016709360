const userEvents = {
   onBoarding: {
      code: "onboarding",
      values: {
         skipped: "skipped",
         finished: "finished"
      }
   }
}

export default userEvents