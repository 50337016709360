import $axios from '../http'
import Centrifuge from 'centrifuge'

class MessengerWS {
   static _hostUrl = process.env.REACT_APP_HOST_URL
   static _socket = null
   static _channelName = null
   static _messageSubscriber = null

   static _onConnect = (ctx) => {
      console.log('connected', ctx)
   }

   static _onDisconnect = async (ctx) => {
      if(ctx.reason === 'expired') {
         try {
            const { data } = await $axios.get(`realtime/token?personalToken=true`, { withCredentials: true })
            if (!data.error) {
               this._socket.setToken(data.data.result)
            } else {
               console.log('failed to refreshing token, error:', data.data.error)
               this._socket.disconnect()
            }
         } catch (err) {
            console.log(err)
         }
      }
   }

   static _onMessage = async ({ data: newMessage }) => {
      this._messageSubscriber && this._messageSubscriber(newMessage)

      if (navigator.serviceWorker.controller) {
         navigator.serviceWorker.controller.postMessage({
            type: "message",
            data: newMessage
         })
      }
   }

   static _createSocket = async () => {
      if (!this._socket) {
         try {
            this._socket = new Centrifuge(`wss://${this._hostUrl}/realtime/connection/websocket`)
            const { data } = await $axios.get(`realtime/token?personalToken=true`, { withCredentials: true })
            if (!data.error) {
               this._socket?.setToken(data.data.result)
               this._socket?.on('connect', this._onConnect)
               this._socket?.on('disconnect', this._onDisconnect)
               this._socket?.subscribe(this._channelName, this._onMessage)
               this._socket?.connect()
            } else {
               window.alert("Error: can't set JWT Token for the connection")
            }
         } catch (err) {
            console.log(err)
         }
      }
   }

   static _deleteSocket = () => {
      this._socket?.disconnect()
      this._socket = null
   }

   static subscribe = (channelName, onMessage) => {
      this._channelName = channelName
      this._messageSubscriber = onMessage
      this._createSocket()
   }

   static unsubscribe = () => {
      this._deleteSocket()
      this._channelName = null
      this._messageSubscriber = null
   }
}

export default MessengerWS