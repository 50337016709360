const determineDateStatus = (dateString) => {
   if (!dateString) return ''
   const targetDate = new Date(dateString)
   const today = new Date()
 
   if (
      targetDate.getDate() === today.getDate() &&
      targetDate.getMonth() === today.getMonth() &&
      targetDate.getFullYear() === today.getFullYear()
   ) {
      return {
         name: 'Сегодня',
         style: 'today'
      }
   } else if (targetDate > today) {
      return {
         name: 'Предстоящая',
         style: 'future'
      }
   } else {
      return {
         name: 'Просрочено',
         style: 'outdated'
      }
   }
}

export default determineDateStatus