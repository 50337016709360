import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import '../modals/MassSend'
import './sidebarList.css'
import DialogTag from './DialogTag'
import SidebarItem from './SidebarItem'
import { settings } from '../../data/settings'
import SidebarContactsList from './SidebarContactsList'
import {convertDate} from "../utils/helper";
import {messageTypes} from '../../utils/consts'
import Search from '../Search'
import DialogsListFallback from '../DialogsListFallback'
import Loader from '../Loader'
import ToggleWithText from '../ToggleWithText'
import FilterDateSelect from '../FilterDateSelect'

const SidebarCard = ({
   handleSetSelectedDialog,
   handleDeleteAttachedTagFromDialog,
   dialogs,
   selectedConversation,
   cardInfo,
   setDialogs,
   isManager,
   allowedTag,
}) => {
   const dialogTagList = cardInfo.dialog_tag

   const [isMoreTagsShow, setIsMoreTagsShow] = useState(false)

   const toggleIsShowMoreTags = () => {
      setIsMoreTagsShow(!isMoreTagsShow)
   }

   const recognizeLastMessage = () => {
      const messageType = cardInfo.is_document
      switch (messageType) {
         case 'text':
            return cardInfo.last_message
         default:
            return messageTypes[messageType] || ''
      }
   }

   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/sidebar/default_avatar.png'
   }

   return (
      <div
         className={
            selectedConversation === cardInfo.dialog_id
               ? 'sidebar-card-chosen'
               : 'sidebar-card'
         }
         onClick={(e) => {
            if (
               e.target.className &&
               e.target.className.includes('sidebar-card')
            ) {
               handleSetSelectedDialog(cardInfo)
            }
         }}
      >
         {cardInfo.dialog_status && (
            <div
               className="sidebar-card-status"
               style={{
                  backgroundColor: cardInfo.dialog_status.color,
                  color: '#fff',
               }}
            >
               {cardInfo.dialog_status.name}
            </div>
         )}
         <div className="sidebar-card-wrapper">
            <div className="sidebar-card-profile-avatar">
               <img
                  className="sidebar-card-profile-avatar-pic"
                  src={
                     cardInfo.dialog_picture
                        ? cardInfo.dialog_picture
                        : `/assets/sidebar/default_avatar.png`
                  }
                  onError={handleImageLoadingError}
                  alt="avatar"
               />
               <img
                  className="sidebar-card-profile-channel-icon"
                  src={`/assets/sidebar/${cardInfo.dialog_domain}-icon.svg`}
                  alt=""
               />
            </div>
            <div className="sidebar-card-conversation-details">
               <div className="sidebar-card-conversation-respondent">
                  {cardInfo.name || cardInfo.client.name}
               </div>
               {(cardInfo.context_name && cardInfo.dialog_channel === 'avito') && (
                  <div className="sidebar-card-conversation-product-name">
                     {cardInfo.context_name}
                  </div>
               )}
               <div className="sidebar-card-conversation-footer">
                  <div className="sidebar-card-conversation-message">
                     {!cardInfo.is_incoming && (
                        <b
                           style={{
                                 color: '#242939',
                                 display: 'inline-block',
                              }}
                           className="sidebar-card-conversation-self-send"
                        >
                           Вы:
                        </b>
                     )}
                     {` ${recognizeLastMessage()}`}
                  </div>
                  {cardInfo.new_message !== 0 && (
                     <div className="sidebar-card-new-message-status">
                        {cardInfo.new_message}
                     </div>
                  )}
               </div>
            </div>
            <div className="sidebar-card-new-message-time-block">
               <span className="sidebar-card-conversation-time">
                  {convertDate(cardInfo.timestamp)}
               </span>
            </div>
         </div>
         {dialogTagList?.length > 0 && (
            <div className="sidebar-card-conversation-tags-list">
               {isMoreTagsShow ? (
                  <>
                     {dialogTagList.map((tag, index) => (
                        <DialogTag
                           onDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                           dialogs={dialogs}
                           allowedTag={allowedTag}
                           isManager={isManager}
                           key={tag.id}
                           cardInfo={cardInfo}
                           currentTag={tag}
                           dialogTagList={dialogTagList}
                           setDialogs={setDialogs}
                        />
                     ))}
                  </>
               ) : (
                  dialogTagList
                     .slice(0, 2)
                     .map((tag, index) => (
                        <DialogTag
                           onDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                           dialogs={dialogs}
                           allowedTag={allowedTag}
                           isManager={isManager}
                           key={tag.id}
                           cardInfo={cardInfo}
                           currentTag={tag}
                           dialogTagList={dialogTagList}
                           setDialogs={setDialogs}
                        />
                     ))
               )}
            </div>
         )}
         {dialogTagList?.length > 2 && (
            <img
               className="sidebar-card-conversation-tags-button"
               onClick={toggleIsShowMoreTags}
               src={
                  isMoreTagsShow
                     ? '/assets/settings/up.svg'
                     : '/assets/settings/more.svg'
               }
            />
         )}
      </div>
   )
}

const SidebarList = ({
   handleDeleteAttachedTagFromDialog,
   dialogsLoading,
   searchValue,
   isUnreadOnlyChecked,
   handleSearchChange,
   handleUnreadOnlyChange,
   handleDateStatusChange,
   selectedDateStatus,
   handleSetSelectedDialog,
   isManager,
   selectedConversation,
   allowedChannel,
   allowedTag,
   allowedStatus,
   selectedChannel,
   sidebarToggle,
   dialogs,
   setDialogs,
   channelList,
   onDialogsScroll,
   newContactInitiationParams,
   showAddContactModal,
   setShowAddContactModal,
   setSettingsToggle,
   toggleSidebarView,
   handleContactCardClick,
   followUpDateEnabled
}) => {
   const [dialogsList, setDialogsList] = useState(dialogs)
   const [disableDialogsScroll, setDisableDialogsScroll] = useState(false)
   const [showFilter, setShowFilter] = useState(false)

   const handleDialogsScroll = async (e) => {
      if (
         e.target.scrollHeight - e.target.scrollTop <=
         e.target.clientHeight + 35 && !disableDialogsScroll 
      ) {
         setDisableDialogsScroll(true)
         await onDialogsScroll()
         setDisableDialogsScroll(false)
      }
   }

   const handleToggleShowFilter = () => setShowFilter(show => !show)

   useEffect(() => {
      setDialogsList(dialogs)
   }, [dialogs])

   const [settingsButtons, setSettings] = useState(settings)

   useEffect(() => {
      if (isManager) {
         setSettings(settings.filter((option) => option.onlyForAdmin !== true))
      }
   }, [isManager])

   const markSettingsButton = (position) => {
      setSettings((prevState) => {
         return prevState.map((button) => {
            if (button.position !== position)
               return { ...button, isChecked: false }
            return { ...button, isChecked: true }
         })
      })
   }

   const handleInputSearch = (term) => {
      term
         ? setDialogsList(
            dialogs.filter((dialog) =>
                 dialog.dialog_name.toLowerCase().startsWith(term.toLowerCase())
              )
           )
         : setDialogsList(dialogs)
   }

   useEffect(() => {
      if (selectedChannel === 'all') {
         setDialogsList(dialogs)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedChannel, dialogs])

   return (
      <>
         {sidebarToggle === 1 && (
            <div className='dialogs-filter'>
               <div className='dialogs-filter__search-container'>
                  <Search
                     searchValue={searchValue}
                     onChange={handleSearchChange} />
                  <button
                     className='dialogs-filter__toggle-filter-btn'
                     onClick={handleToggleShowFilter}
                  >
                     <img src='/assets/sidebar/filter-toggle-icon.svg' alt='' />
                  </button>
               </div>
               <div className={`dialogs-filter__filter-container ${showFilter ? 'dialogs-filter__filter-container_show' : ''}`}>
                  {followUpDateEnabled && (
                     <FilterDateSelect
                        selectedDateStatus={selectedDateStatus}
                        onSelect={handleDateStatusChange}
                     />
                  )}
                  <ToggleWithText
                     checked={isUnreadOnlyChecked}
                     text='Показать непрочитанные'
                     onChange={handleUnreadOnlyChange}
                  />
               </div>
            </div>
         )}
         {sidebarToggle === 1 ? (
            <div
               id='dialogs-list'
               className="sidebar-list"
               onScroll={handleDialogsScroll}
               style={{
                  height: 'calc(98vh - 140px)',
                  display: toggleSidebarView ? 'block' : 'none',
               }}
            >
               {dialogsLoading
                  ? <Loader />
                  : dialogs.length
                     ? dialogs.map((dialog, index) => (
                        <SidebarCard
                           handleSetSelectedDialog={handleSetSelectedDialog}
                           handleDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                           dialogs={dialogs}
                           selectedConversation={selectedConversation}
                           isManager={isManager}
                           allowedTag={allowedTag}
                           key={index}
                           cardInfo={dialog}
                           setDialogs={setDialogs}
                        />
                     ))
                     : <DialogsListFallback />
               }
            </div>
         ) : sidebarToggle === 2 ? (
            <SidebarContactsList
               newContactInitiationParams={newContactInitiationParams}
               channelList={channelList}
               allowedChannel={allowedChannel}
               selectedConversation={selectedConversation}
               isManager={isManager}
               // contactsList={contactsList}
               // setContactsList={setContactsList()}
               // setContacts={setContacts()}
               setShowAddContactModal={setShowAddContactModal}
               showAddContactModal={showAddContactModal}
               toggleSidebarView={toggleSidebarView}
               handleContactCardClick={handleContactCardClick}
            />
         ) : sidebarToggle === 4 ? (
            <div
               className="sidebar-list"
               style={{
                  height: 'calc(68vh - 80px)',
                  display: toggleSidebarView ? 'block' : 'none',
               }}
            >
               {settingsButtons.map((item) => {
                  return (
                     <SidebarItem
                        key={item.position}
                        item={item}
                        toggle={setSettingsToggle}
                        mark={markSettingsButton}
                     />
                  )
               })}
            </div>
         ) : (
            <></>
         )}
      </>
   )
}

export default SidebarList
