import React, { useEffect, useState } from 'react'
import NotificationModal from '../modals/NotificationModal'
import { notificationsData } from '../../utils/consts'

const DialogTag = ({
   onDeleteAttachedTagFromDialog,
   dialogs,
   isManager,
   allowedTag,
   currentTag,
   dialogTagList,
   cardInfo,
   setDialogs,
}) => {
   const [disableDeleteBtn, setDisableDeleteBtn] = useState(false)

   const [notification, setNotification] = useState({
      isVisible: false,
      ...notificationsData.default
   })

   const showNotification = (notificationData) => {
      setNotification(notification => ({
         isVisible: !notification.isVisible,
         ...notificationData
      }))
   }

   const closeNotification = () => setNotification(notification => ({ ...notification, isVisible: false }))

   const handleDeleteClick = async () => {
      if (cardInfo.read_only && isManager) {
         return showNotification(notificationsData.readOnly)
      }
      if (isManager && !allowedTag.includes(currentTag.id)) {
         return showNotification(notificationsData.tagDelition)
      }
      try {
         setDisableDeleteBtn(true)
         await onDeleteAttachedTagFromDialog(currentTag.id, cardInfo)
      } catch (e) {
         setDisableDeleteBtn(false)
      }
   }

   return (
      <>
         <NotificationModal
            isVisible={notification.isVisible}
            title={notification.title}
            text={notification.text}
            ok={closeNotification}
         />
         <span
            className="sidebar-card-conversation-single-tag"
            style={{
               position: 'relative',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
            }}
         >
            <span className="sidebar-conversation-single-tag-name">
               {currentTag.name}
            </span>
            <button
               disabled={cardInfo.dialog_channelStatus !== 'active' || disableDeleteBtn}
               className="sidebar-conversation-single-tag-controls"
               onClick={handleDeleteClick}
            >
               <img
                  src="/assets/settings/delete.svg"
                  style={{
                     width: '9px',
                     height: '9px',
                     marginLeft: '5px',
                     cursor: 'pointer',
                  }}
                  alt=""
               />
            </button>
         </span>
      </>
   )
}

export default DialogTag
