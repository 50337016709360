import { useEffect, useRef } from 'react'

const useAddMessageToUnreadOnVisibility = (autoReadStatus, message, onMessageVisibility) => {
   const messageRef = useRef(null)
   const hasBeenRead = useRef(false)

   const handler = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting && !hasBeenRead.current) {
         hasBeenRead.current = true
         onMessageVisibility(message.external_id)
      }
   }

   useEffect(() => {
      if (message.is_incoming && message.status === 1 && autoReadStatus) {
         const observer = new IntersectionObserver(handler)
         const observableMessageRef = messageRef.current
   
         if (observableMessageRef) observer.observe(observableMessageRef)
   
         return () => {
            if (observableMessageRef) observer.unobserve(observableMessageRef)
         }
      }
   }, [messageRef, handler])

   return [messageRef]
}

export default useAddMessageToUnreadOnVisibility