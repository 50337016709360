import React, { useState } from 'react'
import './ManagerPermissionTooltip.css'
import { useClickOutside } from '../../../hooks'

const ManagerPermissionTooltip = () => {
   const [show, setShow] = useState(false)

   const handleToggle = () => setShow(show => !show)
   const handleClose = () => setShow(false)

   const ref = useClickOutside(handleClose)

   return (
      <div
         ref={ref} 
         className={`manager-permission-tooltip ${show ? 'manager-permission-tooltip_active' : ''}`}
      >
         <button
            className='manager-permission-tooltip__btn'
            onClick={handleToggle}
         >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M8 9.16602C7.72667 9.16602 7.5 8.93935 7.5 8.66602V5.16602C7.5 4.89268 7.72667 4.66602 8 4.66602C8.27333 4.66602 8.5 4.89268 8.5 5.16602V8.66602C8.5 8.93935 8.27333 9.16602 8 9.16602Z" fill="#40B7D9"/>
               <path d="M8.00016 11.5008C7.82016 11.5008 7.65348 11.4341 7.52681 11.3075C7.46681 11.2408 7.42017 11.1675 7.38017 11.0875C7.34683 11.0075 7.3335 10.9208 7.3335 10.8341C7.3335 10.6608 7.40681 10.4874 7.52681 10.3608C7.77348 10.1141 8.22684 10.1141 8.47351 10.3608C8.59351 10.4874 8.66683 10.6608 8.66683 10.8341C8.66683 10.9208 8.64682 11.0075 8.61348 11.0875C8.58015 11.1675 8.53351 11.2408 8.47351 11.3075C8.34684 11.4341 8.18016 11.5008 8.00016 11.5008Z" fill="#40B7D9"/>
               <path d="M7.99996 15.1667C7.55329 15.1667 7.09995 15.0534 6.69995 14.8201L2.73995 12.5334C1.93995 12.0667 1.43994 11.2067 1.43994 10.2801V5.72008C1.43994 4.79341 1.93995 3.93341 2.73995 3.46674L6.69995 1.18008C7.49995 0.713411 8.4933 0.713411 9.29997 1.18008L13.26 3.46674C14.06 3.93341 14.56 4.79341 14.56 5.72008V10.2801C14.56 11.2067 14.06 12.0667 13.26 12.5334L9.29997 14.8201C8.89997 15.0534 8.44663 15.1667 7.99996 15.1667ZM7.99996 1.8334C7.72663 1.8334 7.44662 1.90674 7.19995 2.04674L3.23995 4.3334C2.74662 4.62006 2.43994 5.14674 2.43994 5.72008V10.2801C2.43994 10.8467 2.74662 11.3801 3.23995 11.6667L7.19995 13.9534C7.69328 14.2401 8.30663 14.2401 8.79329 13.9534L12.7533 11.6667C13.2466 11.3801 13.5533 10.8534 13.5533 10.2801V5.72008C13.5533 5.15341 13.2466 4.62006 12.7533 4.3334L8.79329 2.04674C8.55329 1.90674 8.27329 1.8334 7.99996 1.8334Z" fill="#40B7D9"/>
            </svg>
         </button>
         <div className='manager-permission-tooltip__tooltip'>
            <div className='manager-permission-tooltip__tooltip-body'>
               За тегом не закреплен ни один менеджер. Доступ к диалогам есть только у администратора.
            </div>
         </div>
      </div>
   )
}

export default ManagerPermissionTooltip