import { CSSTransition } from 'react-transition-group'
import './basicModal.css'
import './ManagerEditFormModal.css'
import { Button, btnThemes } from '../Button'

const ManagerEditFormModal = ({
   isVisible, 
   title, 
   text,
   okBtnText,
   noBtnText,
   onConfirm, 
   onClose
}) => {
   const handleConfirm = () => {
      onConfirm()
   }

   const handleClose = () => {
      onClose()
   }

   return (
      <CSSTransition
         in={isVisible}
         classNames="basic"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="basic">
            <div className="basic_wrapper">
               <div className="basic_header">
                  <span className="basic_title">{title}</span>
                  <div className="basic-close">
                     <button
                        type='button'
                        className="basic-close-button"
                        onClick={handleClose}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>

               <p className="basic_text">{text}</p>
               <div className="basic_buttons manager-edit-form-modal__footer">
                  {okBtnText && (
                     <Button
                        text={okBtnText}
                        onClick={handleConfirm}
                     />
                  )}
                  {noBtnText && (
                     <Button
                        theme={btnThemes.PRIMARY_SKINNY}
                        text={noBtnText}
                        onClick={handleClose}
                     />
                  )}
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default ManagerEditFormModal