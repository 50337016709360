import './tagsStatus.css'

const PaginationItem = ({ currentPage, setCurrentPage, pageNumbers }) => {
   const moveToPage = (pageNumber) => {
      setCurrentPage(pageNumber)
   }
   const nextPage = () => {
      if (currentPage < pageNumbers.length) {
         setCurrentPage((prevState) => prevState + 1)
      }
   }
   const prevPage = () => {
      if (currentPage !== 1) {
         setCurrentPage((prevState) => prevState - 1)
      }
   }

   return (
      <div className={'tags-status-settings_pagination'}>
         <button
            onClick={prevPage}
            className={'tags-status-settings_pagination-array'}
         >
            <svg
               width="18"
               height="18"
               viewBox="0 0 18 18"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0227 4.10225C12.2424 4.32192 12.2424 4.67808 12.0227 4.89775L7.9205 9L12.0227 13.1023C12.2424 13.3219 12.2424 13.6781 12.0227 13.8977C11.8031 14.1174 11.4469 14.1174 11.2273 13.8977L6.72725 9.39775C6.50758 9.17808 6.50758 8.82192 6.72725 8.60225L11.2273 4.10225C11.4469 3.88258 11.8031 3.88258 12.0227 4.10225Z"
                  fill="#252525"
               />
            </svg>
         </button>
         {pageNumbers.map((page) => {
            return (
               <button
                  className={
                     currentPage === page
                        ? 'tags-status-settings_pagination-button-active'
                        : 'tags-status-settings_pagination-button'
                  }
                  onClick={() => moveToPage(page)}
                  key={page}
               >
                  {page}
               </button>
            )
         })}
         <button
            onClick={nextPage}
            className={'tags-status-settings_pagination-array'}
         >
            <svg
               width="18"
               height="18"
               viewBox="0 0 18 18"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.72725 4.10225C6.94692 3.88258 7.30308 3.88258 7.52275 4.10225L12.0227 8.60225C12.2424 8.82192 12.2424 9.17808 12.0227 9.39775L7.52275 13.8977C7.30308 14.1174 6.94692 14.1174 6.72725 13.8977C6.50758 13.6781 6.50758 13.3219 6.72725 13.1023L10.8295 9L6.72725 4.89775C6.50758 4.67808 6.50758 4.32192 6.72725 4.10225Z"
                  fill="#252525"
               />
            </svg>
         </button>
      </div>
   )
}

export default PaginationItem
