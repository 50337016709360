import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './basicModal.css'
import './EditDialogNameModal.css'

import { Button } from '../Button'

const EditDialogNameModal = ({ isVisible, dialogId, dialogName: prevDialogName, onSubmit, onClose }) => {   
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
   
   const formik = useFormik({
      initialValues: {
         dialogName: prevDialogName
      },
      validationSchema: yup.object().shape({
         dialogName: yup.string()
            .min(3, 'Минимальная длина имени диалога должна быть длинее 2 символов')
            .required('Введите имя диалога')
      }),
      onSubmit: async ({ dialogName }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            if (dialogName === prevDialogName) return onClose()
            await onSubmit({
               dialog_id: dialogId,
               name: dialogName
            })
            onClose()
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   const handleClose = () => onClose()

   useEffect(() => {
      formik.setValues({
         dialogName: prevDialogName
      })
   }, [isVisible, dialogId, prevDialogName])

   return (
      <CSSTransition
         in={isVisible}
         classNames="basic"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="basic">
            <div className="basic_wrapper">
               <div className="basic_header">
                  <span className="basic_title">Изменить имя диалога</span>
                  <div className="basic-close">
                     <button
                        className="basic-close-button"
                        onClick={handleClose}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>
               <form
                  className='edit-dialog-name-form'
                  onSubmit={formik.handleSubmit}
               >
                  {formik.status && (<p className='edit-dialog-name-form__status'>{formik.status}</p>)}
                  <div className='edit-dialog-name-form__body'>
                     <div className='edit-dialog-name-form__control'>
                        <label 
                           htmlFor="dialogName"
                           className={`edit-dialog-name-form__label ${formik.touched.dialogName && formik.errors.dialogName ? 'edit-dialog-name-form__label_error' : ''}`}
                        >
                           {(formik.touched.dialogName && formik.errors.dialogName) || 'Имя диалога'}
                        </label>
                        <input 
                           type="text"
                           id='dialogName'
                           name='dialogName'
                           value={formik.values.dialogName}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        />
                     </div>
                  </div>
                  <div className='edit-dialog-name-form__footer'>
                     <Button
                        type='submit'
                        text='Сохранить'
                        disabled={disableSubmitBtn}
                     />
                  </div>
               </form>
            </div>
         </div>
      </CSSTransition>
   )
}

export default EditDialogNameModal