import { INSTAGRAM, TELEGRAM, WHATSAPP } from "./consts"

// Возвращает текст для label поля username в форме создания контакта
const getUsernameFieldLabel = (channel) => {
   switch (channel) {
      case WHATSAPP:
         return 'Номер телефона'
      case TELEGRAM:
         return '@логин или номер телефона'
      case INSTAGRAM:
         return 'Логин'
      default:
         return 'Имя аккаунта'
   }
}

export default getUsernameFieldLabel