const notificationsData = {
   default: {
      title: 'Уведомление',
      text: 'Дефолтный текст'
   },
   readOnly: {
      title: 'Уведомление',
      text: 'Вам предоставлено только чтение диалогов.'
   },
   tagDelition: {
      title: 'Уведомление',
      text: 'У вас нет прав на удаление данного тега.'
   },
   tagAttaching: {
      title: 'Уведомление',
      text: 'У вас нет прав на прикрепление данного тега.'
   },
   tagAlreadyAttached: {
      title: 'Уведомление',
      text: 'Данный тег уже привязан к диалогу.'
   },
   statusAttaching: {
      title: 'Уведомление',
      text: 'У вас нет прав на назначение данного статуса.'
   },
   tagListEmpty: {
      title: 'Уведомление',
      text: 'Ни один тег не создан. Пожалуйста, создайте теги в меню "Настройки" и затем сохраните разрешения для менеджера.'
   },
   managerTagsRequired: {
      title: 'Уведомление',
      text: 'Для данного менеджера не установлены все необходимые разрешения. Пожалуйста, выберите у Тегов минимум одно разрешение.'
   },
   managerPermissionsRequired: {
      title: 'Уведомление',
      text: 'Для данного менеджера не установлены все необходимые разрешения. Пожалуйста, выберите у Каналов и Статусов минимум по одному разрешению.'
   }
}

export default notificationsData