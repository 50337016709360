import React from 'react'

export const parseRef = (message) => {
   const regLink =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
   let splitMessage = message.split(' ')

   let convertedMessage = splitMessage.map((word, index) => {
      if (word.match(regLink)) {
         return (
            <a
               key={index}
               href={word}
               target="_blank"
               rel="noreferrer"
               style={{
                  color: 'inherit',
               }}
            >
               {word}
            </a>
         )
      }
      return splitMessage.length > 1 ? ' ' + word + ' ' : word
   })
   return convertedMessage
}
