import React from 'react'
import Picker from 'emoji-picker-react'

const EmojiPicker = ({ setChosenEmoji }) => {
   const onEmojiClick = (event, emojiObject) => {
      setChosenEmoji(emojiObject)
   }

   return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
         <Picker onEmojiClick={onEmojiClick} />
      </div>
   )
}

export default EmojiPicker
