const modalsData = {
   managerEditForm: {
      status: {
         title: 'Статус менеджера',
         text: 'Неактивный менеджер временно не имеет доступ к вашему аккаунту. Все сообщения, написанные этим пользователям, останутся помеченными его именем.',
         okBtnText: 'Понятно',
      },
      delete: {
         title: 'Удалить менеджера',
         text: 'Удаление менеджера навсегда ограничит ему доступ к вашему аккаунту. Упоминание имени менеджера будет удалено со всех отправленных им сообщений внутри диалогов. Вы сможете впоследствии создать нового менеджера с этими же данными.',
         okBtnText: 'Удалить менеджера',
         noBtnText: 'Отмена'
      },
   }
}

export default modalsData