import { REVOKE_STATUS, REVOKE_MESSAGE_TEXT } from "../components/utils/helper"

const getNormalizedDialog = (dialog) => {
   const defaultStatus = {
      color: '#F58B3F',
      id: 7,
      name: 'Первичный контакт',
   }

   const normalizedDialog = {
      dialog_id: dialog.id,
      dialog_status: dialog.status || defaultStatus,
      dialog_channel: dialog.channel.type,
      dialog_channelStatus: dialog.channel.status,
      dialog_picture: dialog.client.photo,
      dialog_name: dialog.name || `${dialog.client.name} - ${dialog.channel.username}`,
      dialog_username: dialog.channel.username,
      dialog_tag: dialog.tags,
      client: dialog.client,
      last_message_id: dialog.last_message.id,
      last_message: dialog.last_message.status === REVOKE_STATUS ? REVOKE_MESSAGE_TEXT : dialog.last_message.text,
      status: dialog.last_message.status,
      timestamp: dialog.last_message.timestamp,
      is_incoming: dialog.last_message.is_incoming,
      is_document: dialog.last_message.type,
      new_message: dialog.new_messages,
      dialog_domain: dialog.channel.type,
      read_only: dialog.read_only,
      name: dialog.name,
      context_name: dialog.context_name,
      context_link: dialog.context_link,
      photo: dialog.photo,
      follow_up_date: dialog.follow_up_date
   }

   return normalizedDialog
}

export default getNormalizedDialog